<template>
  <div class="main">
    <div class="topbar">
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <template>
              <Input v-model="issuePeople"
                     placeholder="下发人"
                     class="m-r-10"
                     style="width:160px" />
              <DatePicker type="daterange"
                          :start-date="new Date()"
                          placeholder="请选择时间范围"
                          style="width:260px">
              </DatePicker>
            </template>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <template>
          <div class="flex">
            <Button type="primary"
                    class="m-r-10">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </div>
        </template>
      </div>
      <div class="flex">
        <div class="table">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="columnsNotice"
                 :data="data"
                 border
                 stripe
                 :loading="tableLoading"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
            <template slot-scope="{ row }"
                      slot="operation">
              <Button @click.stop=""
                      type="text">处理</Button>
            </template>
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      issuePeople: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columnsNotice: [{
        type: 'selection',
        width: 45,
        align: 'center'
      },
      {
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '下发人',
        key: 'issuePeople',
        tooltip: true,
        width: 200,
        align: 'center'
      },
      {
        title: '下发时间',
        key: 'issueTime',
        tooltip: true,
        width: 240,
        align: 'center'
      },
      {
        title: '接收人',
        key: 'receivePeople',
        tooltip: true,
        width: 220,
        align: 'center'
      },
      {
        title: '标题',
        key: 'title',
        tooltip: true,
        width: 200,
        align: 'center'
      },
      {
        title: '问题图片',
        key: 'issueImg',
        tooltip: true,
        width: 140,
        align: 'center'
      },
      {
        title: '通知内容',
        key: 'noticeCont',
        tooltip: true,
        width: 400,
        align: 'center'
      },
      {
        title: '操作',
        slot: 'operation',
        align: 'center'
      }
      ],
      selectArr: [],
      data: [],
    };
  },
  computed: {

  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 300
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 300
      }
      this.getList()
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {

    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";

.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
